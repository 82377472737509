<template>
  <v-form ref="form" v-model="formValid">
    <v-container fluid class="d-flex justify-space-between pa-0">
      <v-card elevation="0" class="grey lighten-3" width="50%">
        <v-card-text class="d-flex align-center justify-space-between"
          ><h1 class="mr-5">New Shipment</h1>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2"
            v-if="isUpdating"
            color="warning"
            depressed
            rounded
            @click="generateInvoice"
            :loading="isGenerateLoading"
            :disabled="!!shipment.invoiceId"
            ><i class="fas fa-list mr-2"></i>Generate Invoice</v-btn
          >
          <v-btn
            v-if="isUpdating"
            class="mr-2"
            color="warning"
            depressed
            rounded
            @click="updateShipment"
            :loading="isLoading"
            :disabled="!formValid"
            ><i class="fas fa-cubes mr-2"></i>Update Shipment</v-btn
          >
          <v-btn
            v-else
            class="mr-2"
            color="primary"
            depressed
            rounded
            @click="addShipment"
            :loading="isLoading"
            :disabled="!formValid"
            ><i class="fas fa-cubes mr-2"></i>Add New Shipment</v-btn
          >
          <v-tooltip bottom v-if="isUpdating && !!shipment.invoiceId">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-btn
                  v-if="isUpdating"
                  rounded
                  depressed
                  color="error"
                  :disabled="!!shipment.invoiceId"
                  ><i class="fa-solid fa-trash-can"></i
                ></v-btn>
              </div>
            </template>
            <span>Can't delete. Invoice has been created</span>
          </v-tooltip>
          <v-btn
            v-if="isUpdating && !shipment.invoiceId"
            rounded
            depressed
            color="error"
            @click="deleteDialog = true"
            :loading="isDeleteLoading"
            ><i class="fa-solid fa-trash-can"></i></v-btn
        ></v-card-text>

        <v-card-text class="py-0 mb-2">
          <v-container class="d-flex py-0 my-0 white rounded-xl">
            <v-container
              ><v-text-field
                v-model="shipment.mtlTracking"
                label="MTL Tracking #"
                dense
                rounded
                outlined></v-text-field
            ></v-container>
          </v-container>
        </v-card-text>
        <v-card-text class="py-0 mb-2">
          <v-container class="d-flex py-0 my-0 white rounded-xl">
            <v-container>
              <v-autocomplete
                :rules="[(v) => !!v || 'Sender is required']"
                :items="customers"
                :item-text="getCustomerName"
                item-value="id"
                label="Sender"
                dense
                rounded
                outlined
                v-model="shipment.senderId"
                @change="updateSenderInfo"></v-autocomplete>
              <v-text-field
                :rules="[(v) => !!v || 'Address is required']"
                label="Address Line 1"
                dense
                rounded
                outlined
                v-model="shipment.senderAddress1"></v-text-field>
              <v-text-field
                :rules="[(v) => !!v || 'Address is required']"
                label="Address Line 2"
                dense
                rounded
                outlined
                v-model="shipment.senderAddress2"></v-text-field>
              <v-text-field
                :rules="[(v) => !!v || 'Phone is required']"
                v-model="shipment.senderPhone"
                label="Phone"
                dense
                rounded
                outlined></v-text-field>
              <v-text-field
                v-model="shipment.senderEmail"
                label="Email"
                dense
                rounded
                outlined></v-text-field
            ></v-container>
            <v-container>
              <v-autocomplete
                :rules="[(v) => !!v || 'Receiver is required']"
                :items="customers"
                :item-text="getCustomerName"
                item-value="id"
                label="Receiver"
                dense
                rounded
                outlined
                v-model="shipment.receiverId"
                @change="updateReceiverInfo"></v-autocomplete>
              <v-text-field
                :rules="[(v) => !!v || 'Address is required']"
                label="Address Line 1"
                dense
                rounded
                outlined
                v-model="shipment.receiverAddress1"></v-text-field>
              <v-text-field
                :rules="[(v) => !!v || 'Address is required']"
                label="Address Line 2"
                dense
                rounded
                outlined
                v-model="shipment.receiverAddress2"></v-text-field>
              <v-text-field
                :rules="[(v) => !!v || 'Phone is required']"
                v-model="shipment.receiverPhone"
                label="Phone"
                dense
                rounded
                outlined></v-text-field>
              <v-text-field
                v-model="shipment.receiverEmail"
                label="Email"
                dense
                rounded
                outlined></v-text-field>
            </v-container>
          </v-container>
        </v-card-text>
        <v-card-text class="py-0 mb-2">
          <v-container
            class="py-0 my-0 d-flex justify-space-between white rounded-xl">
            <v-container>
              <v-text-field
                :rules="[
                  (v) => v > 0 || 'Number of Boxes must be greater than 0',
                ]"
                v-model.number="shipment.totalBox"
                label="Number of Boxes"
                dense
                rounded
                outlined></v-text-field>
            </v-container>
            <v-container>
              <v-text-field
                :rules="[(v) => v > 0 || 'Weight must be greater than 0']"
                v-model.number="shipment.totalWeight"
                label="Total Weight"
                dense
                rounded
                outlined
                suffix="Kg"></v-text-field
            ></v-container>
            <v-container>
              <v-text-field
                :rules="[(v) => v > 0 || 'Value must be greater than 0']"
                v-model.number="shipment.totalValue"
                prefix="$"
                label="Total Declared Value"
                dense
                rounded
                outlined></v-text-field
            ></v-container>
          </v-container>
        </v-card-text>
        <v-card-text class="py-0 mb-2">
          <v-container class="py-0 my-0 rounded-xl white">
            <v-container
              ><v-textarea
                rounded
                outlined
                v-model="shipment.description"
                label="Description"></v-textarea
            ></v-container>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card elevation="0" class="grey lighten-3" width="49%">
        <v-card-text
          ><v-container class="d-flex align-center justify-space-between"
            ><v-autocomplete
              :rules="[(v) => !!v || 'Shipment Group is required']"
              rounded
              outlined
              dense
              class="mr-2"
              v-model="shipment.groupId"
              item-text="id"
              label="Shipment Group"
              :items="shipmentGroups"
              required></v-autocomplete
            ><v-autocomplete
              rounded
              outlined
              dense
              v-model="shipment.shipmentStatusId"
              label="Shipment Status"
              :items="shipmentStatuses"
              item-value="id"
              item-text="displayName"
              :disabled="!isUpdating"></v-autocomplete
          ></v-container>
          <v-container class="white rounded-xl">
            <v-container><h2>Parcel List</h2></v-container>
            <v-container
              v-for="(parcel, idx) in parcels"
              :key="`parcel-${idx}`"
              class="py-0 my-0 d-flex justify-space-between">
              <v-container class="py-0 my-0">
                <v-text-field
                  label="Total Weight"
                  dense
                  rounded
                  outlined
                  suffix="Kg"
                  v-model.number="parcel.weight"
                  @change="updateWeight"></v-text-field
              ></v-container>
              <v-container class="py-0 my-0">
                <v-text-field
                  prefix="$"
                  label="Total Declared Value"
                  dense
                  rounded
                  outlined
                  v-model.number="parcel.value"
                  @change="updateValue"></v-text-field
              ></v-container>
              <v-container fluid class="py-0 my-0 w-25">
                <v-btn
                  v-if="!isUpdating"
                  color="red"
                  depressed
                  icon
                  @click="deleteParcel(idx)"
                  ><i class="fas fa-times"></i
                ></v-btn>
                <v-btn
                  v-if="parcel.isActive && isUpdating"
                  color="red"
                  depressed
                  icon
                  @click="removeParcel(idx)"
                  ><i class="fas fa-times"></i
                ></v-btn>
                <v-btn
                  v-if="!parcel.isActive && isUpdating"
                  color="red"
                  depressed
                  icon
                  @click="undoRemoveParcel(idx)"
                  ><i class="fas fa-undo"></i></v-btn></v-container
            ></v-container>

            <v-container
              ><v-btn color="primary" depressed rounded @click="addParcel"
                ><i class="fas fa-plus mr-2"></i>Add</v-btn
              ></v-container
            >
          </v-container>
        </v-card-text>
      </v-card>
      <general-dialog
        :dialog="deleteDialog"
        v-model="deleteDialog"
        :options="deleteOptions"
        @handle-event="deleteShipment"></general-dialog>
    </v-container>
  </v-form>
</template>
<script>
import eventBus from "@/js/event-bus";
import backendService from "@/services/backend-service";
import generalMixin from "@/js/general-mixin.js";
import { InvoiceType, ShipmentStatus } from "@/js/enum.js";
import GeneralDialog from "@/components/GeneralDialog.vue";
export default {
  name: "ShipmentForm",
  mixins: [generalMixin],
  components: { "general-dialog": GeneralDialog },

  data() {
    return {
      formValid: false,
      originalShipment: {},
      originalParcels: [],
      isUpdating: false,
      isGenerateLoading: false,
      isLoading: false,
      parcels: [],
      shipment: {
        mtlTracking: null,
        description: "",
        totalBox: 0,
        totalWeight: 0,
        totalValue: 0,
        shipmentStatusId: this.getIdByEnum(ShipmentStatus.New),
      },
      deleteDialog: false,
      isDeleteLoading: false,
      deleteOptions: {
        btnText: "Yes",
        message: "This action is not revertable!",
        title: "Are you sure you want to delete this shipment?",
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.getUpdateForm();
    }
    this.$refs.form.validate();
  },
  computed: {
    customers() {
      return this.$store.getters["customers"];
    },
    shipmentGroups() {
      return this.$store.getters["shipmentGroups"];
    },
    shipments: {
      get() {
        return this.$store.getters["shipments"];
      },
      set(val) {
        this.$store.commit("setShipments", val);
      },
    },
    paymentStatuses() {
      if (
        this.$store.getters["dropdownToOptions"] &&
        this.$store.getters["dropdownToOptions"]["paymentStatus"]
      )
        return this.$store.getters["dropdownToOptions"]["paymentStatus"]
          .options;
      return [];
    },
    shipmentStatuses() {
      if (
        this.$store.getters["dropdownToOptions"] &&
        this.$store.getters["dropdownToOptions"]["shipmentStatus"]
      )
        return this.$store.getters["dropdownToOptions"]["shipmentStatus"]
          .options;
      return [];
    },
  },
  methods: {
    addParcel() {
      this.parcels.push({ isActive: true, weight: 0, value: 0 });
      this.shipment.totalBox = this.parcels.length;
    },
    addShipment() {
      if (!this.$refs.form.validate()) return;
      this.isLoading = true;
      this.shipment.parcels = this.parcels;
      backendService
        .addShipment(this.shipment)
        .then((response) => {
          this.shipment = response.data;
          this.parcels = this.shipment.parcels;
          this.$store.commit("addShipment", this.shipment);
          this.timer(1000).then(() => {
            this.isLoading = false;
            this.isUpdating = true;
            this.$router.push({
              name: "ShipmentUpdate",
              params: { id: this.shipment.id },
            });
            this.successSnackbar("Successfully added a new shipment");
          });
        })
        .catch((err) => {
          let message = err.response.data;
          this.timer(1000).then(() => {
            if (typeof message == "string" && message.includes("Duplicate")) {
              this.errorSnackbar("Repeated tracking # under shipment list");
            } else {
              this.errorSnackbar("Ops! Something is not right");
            }
            this.isLoading = false;
          });
        });
    },
    deleteShipment() {
      this.isDeleteLoading = true;
      backendService
        .deleteShipmentById(this.shipment.id)
        .then(() => {
          setTimeout(() => {
            this.isDeleteLoading = false;
            this.$router.push({ name: "ShipmentList" });
            eventBus.$emit(
              "setSnackbar",
              "Successfully delete the shipment",
              "success",
              true
            );
          }, 1000);
        })
        .catch(() => {
          this.isDeleteLoading = false;
          eventBus.$emit(
            "setSnackbar",
            "Ops! Something is not right!",
            "error",
            true
          );
        }, 1000);
    },
    deleteParcel(idx) {
      this.parcels.splice(idx, 1);
      this.updateValue();
      this.updateWeight();
      this.shipment.totalBox = this.parcels.length;
    },
    generateInvoice() {
      this.isGenerateLoading = true;
      this.timer(1000).then(() => {
        this.$router.push({
          name: "NewInvoice",
          params: {
            shipment: this.shipment,
            isGenerating: true,
            type: this.getIdByEnum(InvoiceType.Shipment),
          },
        });
      });
    },
    getCustomerName(item) {
      return `${item.id} - ${item.name} - ${item.phone}`;
    },
    getUpdateForm() {
      this.isUpdating = true;
      this.getShipmentById(this.$route.params.id);
    },
    getShipmentById(id) {
      backendService.getShipmentById(id).then((response) => {
        this.shipment = response.data;
        this.parcels = this.shipment.parcels;
        this.originalShipment = JSON.stringify(this.shipment);
      });
    },
    undoRemoveParcel(idx) {
      this.parcels[idx].isActive = true;
      this.updateWeight();
      this.updateValue();
      this.shipment.totalBox += 1;
    },
    removeParcel(idx) {
      this.parcels[idx].isActive = false;
      this.updateWeight();
      this.updateValue();
      this.shipment.totalBox -= 1;
    },
    updateShipment() {
      if (!this.$refs.form.validate()) return;
      this.isLoading = true;
      this.shipment.parcels = this.parcels;
      console.log("check");
      backendService
        .updateShipment(this.shipment)
        .then((response) => {
          this.$store.commit("updateShipment", this.shipment);
          this.timer(1000).then(() => {
            this.shipment = response.data;
            this.parcels = this.shipment.parcels;
            this.originalShipment = JSON.stringify(this.shipment);
            this.isLoading = false;
            this.successSnackbar("Successfully updated a new shipment");
          });
        })
        .catch((err) => {
          let message = err.response.data;
          this.timer(1000).then(() => {
            if (typeof message == "string" || message.includes("Duplicate")) {
              this.errorSnackbar("Repeated tracking # under shipment list");
            } else {
              this.errorSnackbar("Ops! Something is not right");
            }
            this.isLoading = false;
          });
        });
    },
    updateSenderInfo() {
      let customer = this.customers.find((x) => x.id == this.shipment.senderId);
      this.shipment.senderAddress1 = customer.address1;
      this.shipment.senderAddress2 = customer.address2;
      this.shipment.senderPhone = customer.phone;
      this.shipment.senderEmail = customer.email;
    },
    updateReceiverInfo() {
      let customer = this.customers.find(
        (x) => x.id == this.shipment.receiverId
      );
      this.shipment.receiverAddress1 = customer.address1;
      this.shipment.receiverAddress2 = customer.address2;
      this.shipment.receiverPhone = customer.phone;
      this.shipment.receiverEmail = customer.email;
    },
    updateWeight() {
      this.shipment.totalWeight = this.parcels.reduce((ps, a) => {
        if (a.isActive) return ps + (a.weight ? a.weight : 0);
        return ps;
      }, 0);
    },
    updateValue() {
      this.shipment.totalValue = this.parcels.reduce((ps, a) => {
        if (a.isActive) return ps + (a.value ? a.value : 0);
        return ps;
      }, 0);
    },
  },
  watch: {
    "$route.name": {
      handler: function (name) {
        if (name == "NewShipment") {
          this.isLoading = false;
          this.isUpdating = false;
          this.parcels = [];
          this.shipment = {
            mtlTracking: "",
            description: "",
            totalBox: 0,
            totalWeight: 0,
            totalValue: 0,
            shipmentStatusId: this.getIdByEnum(ShipmentStatus.New),
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
