var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-container',{staticClass:"white rounded-xl mb-3"},[_c('v-text-field',{attrs:{"rounded":"","outlined":"","dense":"","label":"Title","rules":_vm.titleRules,"required":""},model:{value:(_vm.value.title),callback:function ($$v) {_vm.$set(_vm.value, "title", $$v)},expression:"value.title"}})],1),_c('v-container',{staticClass:"d-flex justify-space-between white rounded-xl mb-3",attrs:{"fluid":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-text-field',{attrs:{"rounded":"","outlined":"","dense":"","rules":_vm.textRules,"label":"SKU Code","required":""},model:{value:(_vm.value.skuCode),callback:function ($$v) {_vm.$set(_vm.value, "skuCode", $$v)},expression:"value.skuCode"}}),_c('v-autocomplete',{attrs:{"rounded":"","outlined":"","dense":"","rules":_vm.dropdownRules,"items":_vm.brands,"item-text":"displayName","item-value":"id","label":"Brand Name","required":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"text-center"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Brand name not found")]),_c('v-btn',{attrs:{"depressed":"","small":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.dialog = !_vm.dialog;
                    _vm.dropdownId = _vm.brandDropdownId;}}},[_c('i',{staticClass:"fas fa-plus mr-2"}),_vm._v(" Add Brand ")])],1)],1)]},proxy:true}]),model:{value:(_vm.value.brandId),callback:function ($$v) {_vm.$set(_vm.value, "brandId", $$v)},expression:"value.brandId"}}),_c('v-autocomplete',{attrs:{"rounded":"","outlined":"","dense":"","rules":_vm.dropdownRules,"items":_vm.categories,"item-text":"displayName","item-value":"id","label":"Category","required":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"text-center"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Category not found")]),_c('v-btn',{attrs:{"depressed":"","small":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.dialog = !_vm.dialog;
                    _vm.dropdownId = _vm.categoryDropdownId;}}},[_c('i',{staticClass:"fas fa-plus mr-2"}),_vm._v(" Add Brand ")])],1)],1)]},proxy:true}]),model:{value:(_vm.value.lvl1Id),callback:function ($$v) {_vm.$set(_vm.value, "lvl1Id", $$v)},expression:"value.lvl1Id"}}),_c('v-autocomplete',{attrs:{"rounded":"","outlined":"","dense":"","rules":_vm.dropdownRules,"items":_vm.productTypes,"item-text":"displayName","item-value":"id","label":"Type","required":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"text-center"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Product type not found")]),_c('v-btn',{attrs:{"depressed":"","small":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.dialog = !_vm.dialog;
                    _vm.dropdownId = _vm.productTypeDropdownId;}}},[_c('i',{staticClass:"fas fa-plus mr-2"}),_vm._v(" Add Brand ")])],1)],1)]},proxy:true}]),model:{value:(_vm.value.lvl2Id),callback:function ($$v) {_vm.$set(_vm.value, "lvl2Id", $$v)},expression:"value.lvl2Id"}}),_c('v-autocomplete',{attrs:{"rounded":"","outlined":"","dense":"","items":_vm.images,"item-text":"id","label":"Main Image"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-container',{staticClass:"py-0 my-0 d-flex align-center justify-space-around"},[_c('div',{staticClass:"subtitle-1"},[_vm._v(_vm._s(item.id))]),_c('img',{staticClass:"image-preview",attrs:{"src":_vm.getProductPictureUrlById(item.id)}})])]}}]),model:{value:(_vm.value.pictureId),callback:function ($$v) {_vm.$set(_vm.value, "pictureId", $$v)},expression:"value.pictureId"}})],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-text-field',{attrs:{"rounded":"","outlined":"","dense":"","rules":_vm.numberRules,"prefix":"$","label":"Stock Price","required":""},model:{value:(_vm.value.stockPrice),callback:function ($$v) {_vm.$set(_vm.value, "stockPrice", _vm._n($$v))},expression:"value.stockPrice"}}),_c('v-text-field',{attrs:{"rounded":"","outlined":"","dense":"","rules":_vm.numberRules,"label":"Quantity","required":""},model:{value:(_vm.value.quantity),callback:function ($$v) {_vm.$set(_vm.value, "quantity", _vm._n($$v))},expression:"value.quantity"}}),_c('v-autocomplete',{attrs:{"rounded":"","outlined":"","dense":"","items":[
            { val: true, text: 'Yes' },
            { val: false, text: 'No' },
          ],"item-text":"text","item-value":"val","label":"Update Quantity Upon Order"},model:{value:(_vm.value.updateQuantity),callback:function ($$v) {_vm.$set(_vm.value, "updateQuantity", $$v)},expression:"value.updateQuantity"}}),_c('v-text-field',{attrs:{"rounded":"","outlined":"","dense":"","rules":_vm.numberRules,"prefix":"$","label":"Unit Price"},model:{value:(_vm.value.unitPrice),callback:function ($$v) {_vm.$set(_vm.value, "unitPrice", _vm._n($$v))},expression:"value.unitPrice"}}),_c('date-picker',{attrs:{"label":"Exp Date"},model:{value:(_vm.value.expDate),callback:function ($$v) {_vm.$set(_vm.value, "expDate", $$v)},expression:"value.expDate"}}),_c('v-container',{staticClass:"d-flex align-center justify-space-around"},[_c('h3',[_vm._v("Main Image Preview")]),_c('img',{staticClass:"image-preview-lg",attrs:{"src":_vm.getProductPictureUrlById(_vm.value.pictureId)}})])],1)],1),_c('v-container',{staticClass:"white rounded-xl",class:_vm.ckeditorClass,attrs:{"fluid":""}},[_c('h3',{staticClass:"mb-2"},[_vm._v("Product Description")]),_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig},model:{value:(_vm.value.description),callback:function ($$v) {_vm.$set(_vm.value, "description", $$v)},expression:"value.description"}})],1)],1),_c('dropdown-option-dialog',{attrs:{"dropdown-id":_vm.dropdownId},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }