<template>
  <v-container fluid class="d-flex justify-space-between pa-0">
    <v-card elevation="0" class="grey lighten-3 rounded-xl" width="30%">
      <v-card-text class="d-flex align-center justify-space-between">
        <h1>New Shipment Group</h1>
        <v-btn
          v-if="isUpdating"
          color="primary"
          depressed
          rounded
          @click="updateShipmentGroup"
          :loading="isLoading">
          <i class="fas fa-save mr-2"></i>Update Shipment Group
        </v-btn>
        <v-btn
          v-else
          color="primary"
          depressed
          rounded
          @click="addShipmentGroup"
          :loading="isLoading">
          <i class="fas fa-plane-departure mr-2"></i>Add Shipment Group
        </v-btn>
      </v-card-text>
      <v-card-text>
        <v-container class="d-flex flex-column white rounded-xl">
          <v-autocomplete
            rounded
            outlined
            dense
            v-model="shipmentGroup.statusId"
            label="Status"
            :items="statuses"
            item-text="displayName"
            item-value="id"></v-autocomplete
          ><v-date-picker v-model="shipmentGroup.shipmentDate"></v-date-picker>
        </v-container>
      </v-card-text>
      <v-card-text class="d-flex">
        <v-spacer></v-spacer>
      </v-card-text>
    </v-card>
    <v-card class="grey lighten-3" elevation="0" width="70%" v-if="isUpdating">
      <v-card-text>
        <v-container class="d-flex justify-center white rounded-xl">
          <div class="mr-2">
            <v-autocomplete
              rounded
              outlined
              dense
              label="Shipment Status"
              :items="shipmentStatuses"
              item-text="displayName"
              item-value="id"
              v-model="shipmentStatusId"></v-autocomplete>
          </div>
          <div class="mr-2">
            <v-btn
              color="error"
              depressed
              rounded
              @click="applyAllDialog = true"
              :loading="isApplyAllLoading"
              ><i class="fas fa-globe-americas mr-2"></i>Apply to All
              Shipments</v-btn
            >
          </div>
          <div>
            <v-btn
              color="primary"
              depressed
              rounded
              @click="generateReport"
              :loading="isGenerateLoading"
              ><i class="fa-solid fa-download mr-2"></i>Generate Excel
              Report</v-btn
            >
          </div>
        </v-container></v-card-text
      >

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="shipments"
          :items-per-page="15"
          class="elevation-1">
          <template v-slot:item="{ item }">
            <tr
              :class="{
                'payment-paid': checkIfPaid(item.paymentStatusId),
              }">
              <td>
                <a
                  @click="
                    $router.push({
                      name: 'ShipmentUpdate',
                      params: { id: item.id },
                    })
                  "
                  >{{ item.id }}</a
                >
              </td>
              <td>{{ item.mtlTracking }}</td>
              <td>{{ getCustomerName(item.senderId) }}</td>
              <td>{{ getCustomerName(item.receiverId) }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.totalBox }}</td>
              <td>{{ item.totalWeight }}</td>
              <td>{{ getOptionName(item.shipmentStatusId) }}</td>
              <td>
                <span v-if="!item.invoiceId">N/A</span>
                <a
                  v-if="item.invoiceId"
                  @click="
                    $router.push({
                      name: 'InvoiceUpdate',
                      params: { id: item.id },
                    })
                  "
                  >{{ item.invoiceId }}</a
                >
              </td>
            </tr>
          </template></v-data-table
        >
      </v-card-text>
    </v-card>
    <general-dialog
      :dialog="applyAllDialog"
      :options="applyAllOptions"
      v-model="applyAllDialog"
      @handle-event="updateShipmentStatusByGroupId"></general-dialog>
  </v-container>
</template>
<script>
import generalMixin from "@/js/general-mixin";
import backendService from "@/services/backend-service";
import eventBus from "@/js/event-bus";
import GeneralDialog from "@/components/GeneralDialog.vue";
import { ShipmentGroupStatus } from "@/js/enum";
export default {
  name: "ShipmentGroup",
  mixins: [generalMixin],
  components: { "general-dialog": GeneralDialog },
  data() {
    return {
      isGenerateLoading: false,
      isUpdating: false,
      isLoading: false,
      shipmentStatusId: null,
      shipmentGroup: { shipmentDate: null, statusId: null },
      isApplyAllLoading: false,
      applyAllDialog: false,
      applyAllOptions: {
        btnText: "Yes",
        message: "This action is not revertable!",
        title:
          "Are you sure you want to apply the same status to all shipment under this group?",
      },
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        {
          text: "Tracking #",
          align: "start",
          value: "mtlTracking",
        },

        { text: "Sender", value: "senderId" },
        { text: "Receiver", value: "receiverId" },
        { text: "Description", value: "description" },
        { text: "Total Box", value: "totalBox" },
        {
          text: "Total Weight",
          value: "total Weight",
        },
        {
          text: "Shipment Status",
          value: "shipmentStatusId",
        },
        {
          text: "Invoice Id",
          value: "invoiceId",
        },
      ],
    };
  },
  mounted: function () {
    this.shipmentGroup.statusId = this.getIdByEnum(ShipmentGroupStatus.New);
    if (this.$route.params.id) {
      this.getUpdateForm();
    }
  },
  computed: {
    idToOption() {
      return this.$store.getters["idToOption"];
    },
    statuses() {
      if (
        this.$store.getters["dropdownToOptions"] &&
        this.$store.getters["dropdownToOptions"]["shipmentGroupStatus"]
      )
        return this.$store.getters["dropdownToOptions"]["shipmentGroupStatus"]
          .options;
      return [];
    },
    shipmentStatuses() {
      if (
        this.$store.getters["dropdownToOptions"] &&
        this.$store.getters["dropdownToOptions"]["shipmentStatus"]
      )
        return this.$store.getters["dropdownToOptions"]["shipmentStatus"]
          .options;
      return [];
    },
    customers() {
      return this.$store.getters["customers"];
    },
    shipments() {
      return this.$store.getters["shipments"].filter(
        (x) => x.groupId == this.shipmentGroup.id
      );
    },
  },
  methods: {
    addShipmentGroup() {
      this.isLoading = true;
      backendService.addShipmentGroup(this.shipmentGroup).then((response) => {
        this.shipmentGroup.id = response.data;
        this.shipmentGroup.totalWeight = 0;
        this.shipmentGroup.totalBox = 0;
        this.shipmentGroup.totalValue = 0;
        //this.shipmentGroup.statusId = this.getIdByEnum(ShipmentGroupStatus.New);
        this.$store.commit("addShipmentGroup", this.shipmentGroup);
        this.timer(1000).then(() => {
          this.isLoading = false;
          this.isUpdating = true;
          this.$router.push({
            name: "ShipmentGroupUpdate",
            params: { id: response.data },
          });
          eventBus.$emit(
            "setSnackbar",
            "Successfully added a new shipment group",
            "success",
            true
          );
        });
      });
    },
    generateReport() {
      this.isGenerateLoading = true;

      backendService
        .generateReportByGroupId(this.shipmentGroup.id)
        .then((response) => {
          var binary = atob(response.data);

          var array = new Uint8Array(binary.length);
          for (var i = 0; i < binary.length; i++) {
            array[i] = binary.charCodeAt(i);
          }

          const url = window.URL.createObjectURL(new Blob([array]));

          const a = document.createElement("a");
          a.href = url;
          a.download = "report.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();

          this.timer(1000).then(() => {
            this.isGenerateLoading = false;
          });
        });
    },
    getUpdateForm() {
      this.isUpdating = true;
      this.getShipmentGroupById(this.$route.params.id);
    },
    getShipmentGroupById(id) {
      backendService.getShipmentGroupById(id).then((response) => {
        this.shipmentGroup = response.data;
        this.shipmentGroup.shipmentDate = new Date(
          this.shipmentGroup.shipmentDate
        )
          .toISOString()
          .split("T")[0];
      });
    },
    updateShipmentGroup() {
      this.isLoading = true;
      backendService.updateShipmentGroup(this.shipmentGroup).then(() => {
        this.$store.commit("updateShipmentGroup", this.shipmentGroup);
        this.timer(1000).then(() => {
          this.isLoading = false;
          eventBus.$emit(
            "setSnackbar",
            "Successfully update shipment group",
            "success",
            true
          );
        });
      });
    },
    updateShipmentStatusByGroupId() {
      this.isApplyAllLoading = true;
      let shipmentBatchInfo = {
        groupId: this.shipmentGroup.id,
        ShipmentStatusId: this.shipmentStatusId,
      };
      backendService
        .updateShipmentStatusByGroupId(shipmentBatchInfo)
        .then(() => {
          setTimeout(() => {
            this.isApplyAllLoading = false;
            this.$store.dispatch("getShipments");
            eventBus.$emit(
              "setSnackbar",
              "Successfully updated all shipments",
              "success",
              true
            );
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            this.isApplyAllLoading = false;
            eventBus.$emit(
              "setSnackbar",
              "Ops! something is not right",
              "error",
              true
            );
          }, 1000);
        });
    },
  },
  watch: {
    "$route.name": {
      handler: function (name) {
        if (name == "NewShipmentGroup") {
          this.isLoading = false;
          this.isUpdating = false;
          this.shipmentGroup = {
            shipmentDate: null,
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
