<template>
  <v-container fluid class="pa-0">
    <v-card class="grey lighten-3">
      <v-card-text>
        <h1>Contact-Us List</h1>
      </v-card-text>
      <v-card-text class="d-flex flex-wrap">
        <v-card
          width="300px"
          elevation="0"
          class="white rounded-xl mb-2 mr-2"
          v-for="contactUs in contactUsLst"
          :key="contactUs.id">
          <v-card-text>
            <div><i class="fa-solid fa-user mr-2"></i>{{ contactUs.name }}</div>
            <div>
              <i class="fa-solid fa-phone mr-2"></i>{{ contactUs.phone }}
            </div>
            <div>
              <i class="fa-solid fa-envelope mr-2"></i>{{ contactUs.email }}
            </div>
            <div>
              <i class="fa-solid fa-message mr-2"></i>{{ contactUs.message }}
            </div>
            <div>
              <i class="fa-solid fa-calendar-days mr-2"></i>
              {{ convertToUsDate(contactUs.dateCreated) }}
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-text
        v-if="contactUsLst.length == 0"
        class="grey lighten-2 rounded-xl text-center d-flex flex-column"
        ><h2>Nothing to See</h2>
        <i class="fas fa-spider"></i
      ></v-card-text>
      <v-card-text class="mb-2" elevation="0"> </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import backendService from "@/services/backend-service.js";
import generalMixin from "@/js/general-mixin";
export default {
  name: "ContactUs",
  mixins: [generalMixin],
  data() {
    return {
      contactUsLst: [],
    };
  },
  created: function () {
    this.getContactUsLst();
  },
  methods: {
    getContactUsLst() {
      backendService.getContactUsLst().then((response) => {
        this.contactUsLst = response.data;
      });
    },
  },
};
</script>
<style scoped>
.image-preview {
  object-fit: contain;
  image-rendering: smooth;
  width: 100px;
  height: 100px;
}
</style>
