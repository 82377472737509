var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"0","outlined":"","min-width":"350"}},[_c('v-card-title',[_vm._v("Product Detail")]),_c('v-card-text',[_c('div',{staticClass:"d-flex justify-center"},[_c('img',{staticClass:"image-preview",attrs:{"src":_vm.getProductPictureUrlById(_vm.product.pictureId)}})]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-text-field',{attrs:{"rules":[
            (v) => !!v || 'Required',
            (v) => (!!v && v > 0) || 'Must be positive',
          ],"label":"Stock Price","rounded":"","outlined":"","dense":""},model:{value:(_vm.stockInfo.stockPrice),callback:function ($$v) {_vm.$set(_vm.stockInfo, "stockPrice", _vm._n($$v))},expression:"stockInfo.stockPrice"}}),_c('v-text-field',{staticClass:"ml-5",attrs:{"rules":[
            (v) => !!v || 'Required',
            (v) => (!!v && Number.isInteger(v)) || 'Must be a whole number',
            (v) => (!!v && v > 0) || 'Must be positive',
          ],"label":"Quantity","rounded":"","outlined":"","dense":""},model:{value:(_vm.stockInfo.quantity),callback:function ($$v) {_vm.$set(_vm.stockInfo, "quantity", _vm._n($$v))},expression:"stockInfo.quantity"}})],1),_c('v-autocomplete',{attrs:{"rules":[(v) => !!v || 'Required'],"items":_vm.suppliers,"item-text":"displayName","item-value":"id","label":"Supplier","rounded":"","outlined":"","dense":"","required":""},model:{value:(_vm.stockInfo.supplierId),callback:function ($$v) {_vm.$set(_vm.stockInfo, "supplierId", $$v)},expression:"stockInfo.supplierId"}}),_c('date-picker',{attrs:{"rules":[(v) => !!v || 'Required'],"label":"Exp Date","required":""},model:{value:(_vm.stockInfo.expDate),callback:function ($$v) {_vm.$set(_vm.stockInfo, "expDate", $$v)},expression:"stockInfo.expDate"}}),_c('date-picker',{attrs:{"rules":[(v) => !!v || 'Required'],"label":"Date Purchased"},model:{value:(_vm.stockInfo.datePurchased),callback:function ($$v) {_vm.$set(_vm.stockInfo, "datePurchased", $$v)},expression:"stockInfo.datePurchased"}})],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"primary",attrs:{"depressed":"","rounded":"","loading":_vm.isLoading,"disabled":!_vm.formValid},on:{"click":_vm.addStock}},[_vm._v("Confirm")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }